import { ref, toRef, watch, onMounted, onUnmounted } from 'vue'
import { http } from '@/http'
import { setScrollTop, getScrollTop } from '@/utils'
import { needLoading } from '.'

export default function useScrollData() {
  let pageNum = ref(1)
  let scrollElement = ref(null)
  let dataList = ref([])
  let dataListTemp = ref([])
  let dataLength = ref(0)
  let tabType = ref('')
  let scrollTimer = null

  function getData(path, data) {
    needLoading.value = true
    http.post(path, { ...data, pageNum: pageNum.value }).then(res => {
      if(res.result) {
        dataListTemp.value = res.result.dataList
        dataLength.value = res.result.resultTotal
        dataList.value = dataList.value.concat(dataListTemp.value)
      }
    })
  }

  function scrollLoadData(callback) {
    if(scrollTimer) {
      clearTimeout(scrollTimer)
      scrollTimer = null
    }
    if(!dataListTemp.value.length) {
      return false
    }
    try {
      if(getScrollTop() && scrollElement.value.getBoundingClientRect().bottom < window.innerHeight+50) {
        scrollTimer = setTimeout(() => {
          pageNum.value += 1
          callback && callback()
        }, 200)  
      } 
    }
    catch(e) {}
  }

  function handleScroll(callback) {
    window.addEventListener('scroll', () => {
      scrollLoadData(callback)
    })
  }

  function handleWatchData(type, callback) {
    watch(type, (a, b) => {
      if(a !== b) {
        setScrollTop(0)
        pageNum.value = 1
        dataList.value = []
        callback && callback(a)
      }
    })
  }

  onUnmounted(() => {
    window.removeEventListener('scroll', scrollLoadData)
  })

  return {
    pageNum,
    tabType,
    getData,
    dataList,
    dataListTemp,
    handleScroll,
    handleWatchData,
    scrollElement,
    dataLength
  }
}