<script>
import { onMounted, onUnmounted, watch, ref, reactive ,computed } from 'vue'
import { http } from '@/http'
import { setScrollTop, getScrollTop , yearMonth} from '@/utils'
import { needLoading, loading } from '@/reactive'
import useScrollData from '@/reactive/useScrollData'
import { ActionSheet , DatetimePicker } from 'vant'
import {useRoute} from 'vue-router'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
  },
  setup() {
    const route = useRoute()

    let userId = route.query.userId
    let name = route.query.name
    let shopList = ref([])
    
    //获取门店列表
    const shopDataList = () => {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.listUserShop',{userId: userId}).then(res => {
        if(res.result){
          loading.value = false
          shopList.value = res.result
        }
      })
    }
    onMounted(() => {
      document.title = route.query.name
      shopDataList()
    })

    return {
      shopList,
      loading
    }
  }

}
</script>
<template>
  <div class="r-box">
    <ul class="shop-ul" v-if="shopList.length">
      <li class="shop-item list-item" v-for="(item,index) in shopList" :key="index">
        <p class="item-a">{{item.shopName}}</p>
        <p><img src="../assets/images/shop-file.png" style="width: .2rem;height:.26rem" alt="">{{item.goodsCategoryName}}</p>
        <p class="item-tell"><img src="../assets/images/shop-tell.png" style="width: .26rem;height:.26rem" alt="">{{item.shopContact}}：{{item.shopTel}}</p>
        <p><img src="../assets/images/shop-position.png" style="width: .23rem;height:.27rem" alt="">{{item.shopAddr}}</p>
        <p class="business"><img src="../assets/images/zizi.png" style="width: .26rem;height:.24rem" alt="">营业资质<span :style="item.shopMallDataStatus === '未认证' ? 'color:#999999':'color:#00ABEB'">{{item.shopMallDataStatus}}</span></p>
      </li>
    </ul>
    <p class="no-data" v-if="loading === false && !shopList.length" style="text-align: center; font-size:.28rem">暂无数据 :(</p>
  </div>
</template>

<style lang="less" scoped>
  .list-item{
    margin-bottom: 10px;
    background: #fff;
    border-radius: 6px;
    font-size: .28rem;
    color: #333333;
  }
  .shop-item{
    padding: .31rem;
      .item-a{
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-bottom: .22rem;
        margin-bottom: .22rem;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
      }
      .item-tell{
         margin: .15rem 0;
      }
      .business{
        margin-top: .2rem;
        span{
          margin-left: auto;
        }
      }
      P{
        display: flex;
        align-items: baseline;
        padding: 0 .09rem;
        line-height: .35rem;
        img{
          margin-right: .5rem;
        }
      }
  }
  .shop-ul{
    margin-top: 12px;
  }
  .no-data{
    margin-top: 12px;
    padding: 15px;
    background: #fff;
    border-radius: 6px;
  }
</style>